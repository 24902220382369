import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Stack } from "react-bootstrap";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  ChartDataLabels
);

const chartOptions = (isFinal) => {
  return {
    animation: true,
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: 8,
    },
    plugins: {
      datalabels: {
        borderColor: "white",
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 25,
        borderWidth: 2,
        padding: 4,
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage;
        },
        display: (context) => {
          var dataset = context.dataset;
          var value = dataset.data[context.dataIndex];
          return value > 0;
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
};

function ESGChart({ data, weightedScale }) {
  function mapData(_data, finalValues) {
    if (!_data) {
      return { labels: [], dataSets: [] };
    }
    const labels = _data.map((item) => item.name) ?? [];
    const colors = _data.map((item) => item.color) ?? [];
    // const icons = _data.map((item) => item.icon) ?? [];

    // math for the multiplied value chart
    const values = _data.map((item) => item.value) ?? [];
    const multiplied = _data.map((i) => i.value * i.multiplier) ?? [];

    return {
      labels,
      datasets: [
        {
          backgroundColor: colors,
          data: finalValues ? multiplied : values,
          datalabels: {
            align: "start + 20%",
            anchor: "start",
          },
        },
      ],
    };
  }

  const scales = () => {
    // weighted scale is the weighted total / raw total
    let x = weightedScale;
    let r = 100 * x;
    let l = 100;

    // convert the left and right into their respective percentage based widths
    let t = l + r;
    r = (r / t) * 100;
    l = (l / t) * 100;

    return { l: String(l + "%"), r: String(r + "%") };
  };

  return (
    <Stack direction="horizontal" align-items="center">
      <div className="align-middle" style={{ width: scales().l }}>
        <Doughnut
          type="Doughnut"
          data={mapData(data, false)}
          redraw={false}
          options={chartOptions(false)}
        />
        <p className="text-center text-muted">
          <small>Raw Values</small>
        </p>
      </div>
      <div />
      <div className="align-middle" style={{ width: scales().r }}>
        <Doughnut
          id="weightedValues"
          type="Doughnut"
          data={mapData(data, true)}
          redraw={false}
          options={chartOptions(true)}
        />
        <p className="text-center text-muted">
          <small>Weighted Values</small>
        </p>
      </div>
    </Stack>
  );
}

export default ESGChart;
